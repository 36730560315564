import { ComponentProps } from 'react'
import { styled } from '../../theme/stitches.config'

export const Mono = styled('p', {
  color: '$fgHigh',
  lineHeight: '$4',
  fontFamily: '$oracleTriple',
  fontFeatureSettings: "'ss18' on, 'ss17' on, 'ss19' on",

  variants: {
    variant: {
      base: {
        fontSize: '$base'
      },
      small: {
        fontSize: '$small'
      }
    }
  },

  defaultVariants: {
    variant: 'base'
  }
})

type MonoProps = ComponentProps<typeof Mono>

export type { MonoProps }
