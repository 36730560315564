import React, { Children, ElementRef, cloneElement } from 'react'
import { Body, BodyProps } from '@sc/components/Typography'
import { styled, StyledProps } from '@sc/theme/stitches.config'

const TextGroupPrimary = styled(Body, {
  variants: {
    level: {
      primary: {
        color: '$fgHigh'
      },
      secondary: {
        color: '$fgMed'
      }
    }
  },
  defaultVariants: {
    level: 'primary'
  }
})

const TextGroupSecondary = styled(Body, {
  variants: {
    level: {
      primary: {
        color: '$fgMed'
      },
      secondary: {
        color: '$fgLow'
      }
    }
  },
  defaultVariants: {
    level: 'primary'
  }
})

const TextGroupContainer = styled('div', {
  display: 'flex',

  variants: {
    /**
     * Sets the orientation in which the content will be displayed.
     * @default horizontal
     */
    orientation: {
      horizontal: {
        stackH: '$paddingBetweenMed'
      },
      vertical: {
        stack: '$paddingBetweenZero'
      }
    },
    variant: {
      // Set them to combine with orientation
      base: {},
      small: {}
    }
  },

  defaultVariants: {
    orientation: 'horizontal'
  },

  compoundVariants: [
    {
      orientation: 'horizontal',
      variant: 'small',
      css: {
        stackH: '$paddingBetweenSmall'
      }
    }
  ]
})

export type TextGroupPrimaryProps = StyledProps<typeof TextGroupPrimary>

type TextGroupContainerProps = StyledProps<typeof TextGroupContainer>

type TextGroupRef = ElementRef<typeof TextGroupContainer>
export type TextGroupProps = TextGroupContainerProps & {
  children: JSX.Element | Array<JSX.Element | undefined | null | ''> // Allow the children to be optional
  /**
   * Sets the color of the `Body` content.
   * @default primary
   */
  level?: TextGroupPrimaryProps['level']
  variant?: BodyProps['variant']
}

const getPropsToReplace =
  (
    baseVariant: TextGroupProps['variant'],
    baseLevel: TextGroupProps['level']
  ) =>
  (
    childVariant?: TextGroupProps['variant'],
    childLevel?: TextGroupProps['level']
  ) => {
    const variant = childVariant || baseVariant
    const level = childLevel || baseLevel
    return {
      ...(variant ? { variant } : {}),
      ...(level ? { level } : {})
    }
  }

const isJSXElement = (
  element: '' | JSX.Element | null | undefined
): element is JSX.Element => {
  return Boolean((element as JSX.Element)?.props)
}

const TextGroupRoot = React.forwardRef<TextGroupRef, TextGroupProps>(
  ({ variant, level, children, ...props }, ref) => {
    const replaceProps = getPropsToReplace(variant, level)
    const childrenWithProps = Children.map(children, (child) => {
      const propsToReplace = isJSXElement(child)
        ? replaceProps(child?.props?.variant, child?.props?.level)
        : replaceProps()

      return child ? cloneElement(child, propsToReplace) : null
    })

    return (
      <TextGroupContainer ref={ref} variant={variant} {...props}>
        {childrenWithProps}
      </TextGroupContainer>
    )
  }
)

export const TextGroup = {
  Root: TextGroupRoot,
  Primary: TextGroupPrimary,
  Secondary: TextGroupSecondary
}
