import React, { ComponentProps, ElementRef } from 'react'
import AddSmall from '@sc/assets/icons/add-small.svg'
import Add from '@sc/assets/icons/add.svg'
import ArrowDown from '@sc/assets/icons/arrow-down.svg'
import ArrowLeftSmall from '@sc/assets/icons/arrow-left-small.svg'
import ArrowLeft from '@sc/assets/icons/arrow-left.svg'
import ArrowRightSmall from '@sc/assets/icons/arrow-right-small.svg'
import ArrowRight from '@sc/assets/icons/arrow-right.svg'
import ArrowUpRight from '@sc/assets/icons/arrow-up-right.svg'
import ArrowUp from '@sc/assets/icons/arrow-up.svg'
import CheckSmall from '@sc/assets/icons/check-small.svg'
import Check from '@sc/assets/icons/check.svg'
import ChevronDownSmall from '@sc/assets/icons/chevron-down-small.svg'
import ChevronDown from '@sc/assets/icons/chevron-down.svg'
import ChevronLeft from '@sc/assets/icons/chevron-left.svg'
import ChevronRight from '@sc/assets/icons/chevron-right.svg'
import ChevronUpSmall from '@sc/assets/icons/chevron-up-small.svg'
import ChevronUp from '@sc/assets/icons/chevron-up.svg'
import CircleSmall from '@sc/assets/icons/circle-small.svg'
import Circle from '@sc/assets/icons/circle.svg'
import CloseSmall from '@sc/assets/icons/close-small.svg'
import Close from '@sc/assets/icons/close.svg'
import Copy from '@sc/assets/icons/copy.svg'
import EllipsisSmall from '@sc/assets/icons/ellipsis-small.svg'
import Ellipsis from '@sc/assets/icons/ellipsis.svg'
import PlaySmall from '@sc/assets/icons/play-small.svg'
import Play from '@sc/assets/icons/play.svg'
import SquareSmall from '@sc/assets/icons/square-small.svg'
import Square from '@sc/assets/icons/square.svg'
import StopSmall from '@sc/assets/icons/stop-small.svg'
import Stop from '@sc/assets/icons/stop.svg'
import Union from '@sc/assets/icons/union.svg'
import { styled } from '@sc/theme/stitches.config'

const icons = {
  add: Add,
  addSmall: AddSmall,
  arrowUpRight: ArrowUpRight,
  arrowUp: ArrowUp,
  arrowDown: ArrowDown,
  arrowLeft: ArrowLeft,
  arrowLeftSmall: ArrowLeftSmall,
  arrowRight: ArrowRight,
  arrowRightSmall: ArrowRightSmall,
  check: Check,
  checkSmall: CheckSmall,
  chevronUp: ChevronUp,
  chevronUpSmall: ChevronUpSmall,
  chevronDown: ChevronDown,
  chevronDownSmall: ChevronDownSmall,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  circle: Circle,
  circleSmall: CircleSmall,
  close: Close,
  closeSmall: CloseSmall,
  copy: Copy,
  ellipsis: Ellipsis,
  ellipsisSmall: EllipsisSmall,
  play: Play,
  playSmall: PlaySmall,
  stop: Stop,
  stopSmall: StopSmall,
  square: Square,
  squareSmall: SquareSmall,
  union: Union
}

export type IconName =
  | 'add'
  | 'arrowUpRight'
  | 'ellipsis'
  | 'arrowLeft'
  | 'arrowRight'
  | 'arrowUp'
  | 'arrowDown'
  | 'arrowRight'
  | 'copy'
  | 'stop'
  | 'play'
  | 'union'
  | 'check'
  | 'chevronDown'
  | 'chevronLeft'
  | 'chevronRight'
  | 'chevronUp'
  | 'circle'
  | 'close'
  | 'square'

const IconContainer = styled('div', {
  display: 'grid',
  placeItems: 'center',
  color: '$fgHigh',
  borderRadius: '$M',
  variants: {
    variant: {
      xsmall: {
        size: '$iconSmall'
      },
      small: {
        size: '$iconSmall'
      },
      large: {
        size: '$iconBase'
      },
      logo: {
        size: '2rem'
      }
    }
  },
  defaultVariants: {
    variant: 'large'
  }
})

type IconRef = ElementRef<typeof IconContainer>
export type IconProps = ComponentProps<typeof IconContainer> & {
  name: IconName
}

export const Icon = React.forwardRef<IconRef, IconProps>(
  ({ name, ...props }, ref) => {
    // The icon size is tied to the variant of the box.
    // For small, use "small" icons and regular ones for the others
    const resolved = (
      props.variant === 'xsmall' || props.variant === 'small'
        ? name + 'Small'
        : name
    ) as keyof typeof icons

    const Svg = icons[resolved]

    if (!Svg) {
      return null
    }

    return (
      <IconContainer data-icon-box ref={ref} {...props}>
        <Svg />
      </IconContainer>
    )
  }
)
