import { ComponentProps } from 'react'
import { styled } from '../../theme/stitches.config'

export const Body = styled('p', {
  color: '$fgHigh',
  fontFamily: '$octave',

  variants: {
    variant: {
      base: {
        fontSize: '$base',
        lineHeight: '$3'
      },
      xsmall: {
        fontSize: '$xsmall',
        lineHeight: '$3'
      },
      small: {
        fontSize: '$small',
        lineHeight: '$3'
      },
      large: {
        fontSize: '$large',
        lineHeight: '$2'
      }
    },
    /**
     * Makes the text bold
     */
    bold: {
      true: {
        fontWeight: '$medium'
      }
    },
    opacity: {
      default: {},
      med: {
        color: '$fgMed'
      },
      low: {
        color: '$fgLow'
      }
    },
    /**
     * Idents the text
     */
    indented: {
      true: {
        textIndent: '3.2rem',
        '@sm': {
          textIndent: '3.6rem'
        }
      }
    }
  },

  defaultVariants: {
    variant: 'base'
  },

  compoundVariants: [
    {
      variant: 'large',
      indented: true,
      css: {
        textIndent: '3.2rem',
        '@sm': {
          textIndent: '7.2rem'
        }
      }
    }
  ]
})

type BodyProps = ComponentProps<typeof Body>

export type { BodyProps }
