import { ComponentProps } from 'react'
import { styled } from '../../theme/stitches.config'

// Maybe use a different text element?
export const Heading = styled('p', {
  color: '$fgHigh',
  fontFamily: '$octave',

  variants: {
    variant: {
      extraLarge: {
        fontSize: '$extraLarge',
        lineHeight: '$1'
      },
      extraLargeMono: {
        fontFamily: '$oracleTriple',
        fontSize: '$extraLarge',
        lineHeight: '$3'
      },
      sub: {
        fontSize: '$base',
        lineHeight: '$3',
        textTransform: 'uppercase',
        textAlign: 'center'
      }
    },
    bold: {
      true: {
        fontWeight: '$medium'
      }
    },
    uppercase: {
      true: {
        textTransform: 'uppercase'
      }
    }
  },

  defaultVariants: {
    variant: 'extraLarge'
  }
})

type HeadingProps = ComponentProps<typeof Heading>

export type { HeadingProps }
